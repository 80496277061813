import React from "react"
import { useEffect, useState } from "react"

const RandomCharacterArt = () => {
  const [character, setCharacter] = useState("")
  const [color, setColor] = useState("")
  const [backgroundColor, setBackgroundColor] = useState("")

  useEffect(() => {
    // Generate a random character and color
    const randomCharacter = generateRandomCharacter()
    const randomColor = generateRandomColor()
    setCharacter(randomCharacter)
    setColor(randomColor)

    // Choose a contrasting background color
    const contrastingColor = getContrastingColor(randomColor)
    setBackgroundColor(contrastingColor)
  }, [])

  const handleClick = () => {
    // Generate a new random character and color
    const randomCharacter = generateRandomCharacter()
    const randomColor = generateRandomColor()
    setCharacter(randomCharacter)
    setColor(randomColor)

    // Choose a contrasting background color
    const contrastingColor = getContrastingColor(randomColor)
    setBackgroundColor(contrastingColor)
  }

  return (
    <div className="relative w-full h-full">
      <div
        className="random-character w-full contraption text-[60vh] leading-tight p-6 h-full flex justify-center"
        style={{ color, backgroundColor }}
      >
        {character}
      </div>
      <button
        onClick={handleClick}
        className="md:text-xs pointer uppercase text-[0.625rem] gap-1 font-bold tracking-widest hover:opacity-75 text-zinc-800 bg-white/30 p-2 absolute top-2 right-2  rounded  inline-flex items-center"
      >
        👀 Show me another
      </button>
    </div>
  )
}

function generateRandomCharacter() {
  // Generate a random character
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  return characters[Math.floor(Math.random() * characters.length)]
}

function generateRandomColor() {
  // Generate random RGB values
  const r = Math.floor(Math.random() * 256)
  const g = Math.floor(Math.random() * 256)
  const b = Math.floor(Math.random() * 256)
  return `rgb(${r}, ${g}, ${b})`
}

function getContrastingColor(color) {
  const r = parseInt(color.slice(4, 7))
  const g = parseInt(color.slice(9, 12))
  const b = parseInt(color.slice(14, 17))

  return `rgb(${r - 50}, ${g - 50}, ${b - 50})`
}

export default RandomCharacterArt
