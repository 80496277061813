import React, { useState } from "react"

const shapes = ["circle", "square", "diamond", "triangle", "hexagon"]

const Shape = ({ character, shape }) => {
  // Set the style for the shape based on the chosen shape
  let style = {}
  switch (shape) {
    case "circle":
      style = {
        borderRadius: "50%",
        border: `1em solid ${character}`,
        display: "inline-block",
        width: "100px",
        height: "100px",
        textAlign: "center",
        lineHeight: "100px",
      }
      break
    case "square":
      style = {
        border: `1em solid ${character}`,
        display: "inline-block",
        width: "100px",
        height: "100px",
        textAlign: "center",
        lineHeight: "100px",
      }
      break
    case "diamond":
      style = {
        border: `1em solid ${character}`,
        display: "inline-block",
        width: "100px",
        height: "100px",
        textAlign: "center",
        lineHeight: "100px",
        transform: "rotate(45deg)",
      }
      break
    case "triangle":
      style = {
        border: `1em solid ${character}`,
        display: "inline-block",
        width: "0",
        height: "0",
        textAlign: "center",
        lineHeight: "100px",
        borderRight: "50px solid transparent",
        borderBottom: "100px solid",
        borderLeft: "50px solid transparent",
      }
      break
    case "hexagon":
      style = {
        border: `1em solid ${character}`,
        display: "inline-block",
        width: "100px",
        height: "100px",
        textAlign: "center",
        lineHeight: "100px",
        transform: "rotate(60deg)",
      }
      break
    default:
      break
  }

  return <div style={style}>{character}</div>
}

const Shapes = () => {
  const [character, setCharacter] = useState("X")
  const [shape, setShape] = useState("circle")

  return (
    <div>
      <input
        type="text"
        value={character}
        onChange={e => setCharacter(e.target.value)}
      />
      <select value={shape} onChange={e => setShape(e.target.value)}>
        {shapes.map(s => (
          <option key={s} value={s}>
            {s}
          </option>
        ))}
      </select>
      <Shape character={character} shape={shape} />
    </div>
  )
}

export default Shapes
