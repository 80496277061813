import React, { useState, useEffect } from "react"

const FontDemo = ({ font, fontUrl, exampleWord }) => {
  const [text, setText] = useState(exampleWord)
  const [fontSize, setFontSize] = useState(75)
  useEffect(() => {
    const fontStyle = document.createElement("style")
    fontStyle.innerHTML = `
     @font-face {
        font-family: '${font}';
        src: url('${fontUrl}');
      }
    `
    document.head.appendChild(fontStyle)
  }, [])

  return (
    <form>
      <h1 className="font-light text-xs uppercase tracking-widest mb-0">
        Test Drive
      </h1>
      <label className="inline-flex items-center w-full">
        {fontSize}px
        <input
          type="range"
          min="10"
          max="200"
          className="w-full ml-3 accent-purple-500 bg-purple-400 border-0"
          value={fontSize}
          onChange={e => setFontSize(e.target.value)}
        />
      </label>
      <br />

      <p
        className="w-full border-0 placeholder:text-zinc-500 leading-normal active:border-0 active:outline-0 border-transparent focus:border-transparent focus:ring-0"
        style={{
          fontFamily: font,
          fontSize: `${fontSize}px`,
          textTransform: "uppercase",
        }}
        onInput={e => setText(e.target.innerText)}
      >
        {text}
      </p>
    </form>
  )
}

export default FontDemo
