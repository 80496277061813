import * as React from "react"
import { useEffect, useRef } from "react"
// import Layout from "../components/layout"
import Seo from "../components/seo"
import FontDemo from "../components/FontDemo"
import RandomCharacter from "../components/RandomCharacterArt"
import Shapes from "../components/ShapeCharacter"

const ScoutMerch = () => {
  const button = useRef(null)
  const quantity = useRef(null)
  const select = useRef(null)

  useEffect(() => {
    // quantity.current.addEventListener("change", () => {
    //   button.current.setAttribute("data-item-quantity", quantity.current.value)
    // })
    select.current.addEventListener("change", () => {
      button.current.setAttribute(
        "data-item-custom1-value",
        select.current.value
      )
    })
  }, [])

  return (
    <div className="container mx-auto">
      <Seo title="Font Template" />
      <div className="p-2">
        <select
          ref={select}
          id="commercial_license"
          className="mr-2 md:text-base text-sm"
        >
          <option value="1-5 User">1-5 User Commercial License ($29.00)</option>
          <option value="6-10 User">
            6-10 User Commercial License ($50.75)
          </option>
          <option value="11-15 User">
            11-15 User Commercial License ($58.00)
          </option>
          <option value="16-20 User">
            16-20 User Commercial License ($69.60)
          </option>
          <option value="21-25 User">
            21-25 User Commercial License ($84.10)
          </option>
          <option value="26-30 User">
            26-30 User Commercial License ($98.60)
          </option>
          <option value="31-35 User">
            31-35 User Commercial License ($110.20)
          </option>
          <option value="36-40 User">
            36-40 User Commercial License ($124.70)
          </option>
          <option value="41-45 User">
            41-45 User Commercial License ($136.30)
          </option>
          <option value="46-50 User">
            46-50 User Commercial License ($150.80)
          </option>
          <option value="51-55 User">
            51-55 User Commercial License ($162.40)
          </option>
          <option value="56-60 User">
            56-60 User Commercial License ($176.90)
          </option>
          <option value="61-65 User">
            61-65 User Commercial License ($188.50)
          </option>
          <option value="66-70 User">
            66-70 User Commercial License ($200.10)
          </option>
          <option value="71-75 User">
            71-75 User Commercial License ($214.60)
          </option>
          <option value="76-80 User">
            76-80 User Commercial License ($226.20)
          </option>
          <option value="81-85 User">
            81-85 User Commercial License ($240.70)
          </option>
          <option value="86-90 User">
            86-90 User Commercial License ($252.30)
          </option>
          <option value="91-95 User">
            91-95 User Commercial License ($266.80)
          </option>
          <option value="96-100 User">
            96-100 User Commercial License ($278.40)
          </option>
        </select>
        <button
          ref={button}
          className="snipcart-add-item disabled:opacity-90 disabled:hover:opacity-100 disabled:cursor-not-allowed flex-shrink-0 text-white mb-4 bg-purple-500 border-0 py-2 px-4 focus:outline-none hover:bg-purple-600 rounded font-bold tracking-widest text-xs uppercase md:mt-4 mt-0"
          data-item-id="IBI-TF-CONTRAPTION"
          data-item-price="29.00"
          data-item-url={"https://www.budmen.com/store/contraption"}
          data-item-description="Contraption, a display typeface inspired by tinker toys and natural intersections everywhere."
          //   data-item-image="/assets/images/starry-night.jpg"
          data-item-name="Contraption Typeface"
          data-item-max-quantity="1"
          data-item-custom1-name="Commercial License"
          data-item-custom1-options="1-5 User| 6-10 User[+21.75]|11-15 User[+29.00]|16-20 User[+40.60]|21-25 User[+55.10]|26-30 User[+69.60]|31-35 User[+81.20]|36-40 User[+95.70]|41-45 User[+107.30]|46-50 User[+121.80]|51-55 User[+133.40]|56-60 User[+147.90]|61-65 User[+159.50]|66-70 User[+171.10]|71-75 User[+185.60]|76-80 User[+197.20]|81-85 User[+211.70]|86-90 User[+223.30]|91-95 User[+237.80]| 96-100 User[+249.40]"
          data-item-custom1-value="1-5 Users"
          data-item-file-guid="a42ce4e1-5bd5-4730-9ff3-7c549b905faf"
        >
          Add to cart
        </button>
      </div>
      <div className="p-2">
        {/* <FontDemo font="contraption-v1regular"></FontDemo> */}
      </div>
      {/* <div className="container p-2">
        <FontDemo2 font="contraption-v1regular"></FontDemo2>
      </div> */}

      <section className="flex md:flex-row flex-col">
        <div className="md:w-1/2 w-full px-2 pb-2 md:pl-4">
          <h1 className="font-light text-xs uppercase tracking-widest mb-0 border-t-2 pt-4 border-purple-200">
            CHARACTERS
          </h1>
          <div className="w-full  text-7xl grid grid-cols-4 gap-2 items-center contraption">
            <span className="contraption flex items-center justify-left">
              A
            </span>
            <span className="contraption flex items-center justify-left">
              B
            </span>
            <span className="contraption flex items-center justify-left">
              C
            </span>
            <span className="contraption flex items-center justify-left">
              D
            </span>
            <span className="contraption flex items-center justify-left">
              E
            </span>
            <span className="contraption flex items-center justify-left">
              F
            </span>
            <span className="contraption flex items-center justify-left">
              G
            </span>
            <span className="contraption flex items-center justify-left">
              H
            </span>
            <span className="contraption flex items-center justify-left">
              I
            </span>
            <span className="contraption flex items-center justify-left">
              J
            </span>
            <span className="contraption flex items-center justify-left">
              K
            </span>
            <span className="contraption flex items-center justify-left">
              L
            </span>
            <span className="contraption flex items-center justify-left">
              M
            </span>
            <span className="contraption flex items-center justify-left">
              N
            </span>
            <span className="contraption flex items-center justify-left">
              O
            </span>
            <span className="contraption flex items-center justify-left">
              P
            </span>
            <span className="contraption flex items-center justify-left">
              Q
            </span>
            <span className="contraption flex items-center justify-left">
              R
            </span>
            <span className="contraption flex items-center justify-left">
              S
            </span>
            <span className="contraption flex items-center justify-left">
              T
            </span>
            <span className="contraption flex items-center justify-left">
              U
            </span>
            <span className="contraption flex items-center justify-left">
              V
            </span>
            <span className="contraption flex items-center justify-left">
              W
            </span>
            <span className="contraption flex items-center justify-left">
              X
            </span>
            <span className="contraption flex items-center justify-left">
              Y
            </span>
            <span className="contraption flex items-center justify-left">
              Z
            </span>
          </div>
        </div>
        {/* <div className="md:w-1/2 w-full">
          <RandomCharacter></RandomCharacter>
        </div> */}
      </section>
      <div className="p-2">
        <Shapes shape="circle" character="Y"></Shapes>
      </div>
    </div>
  )
}
export default ScoutMerch
